.solutionCapabilitiesBlock{
    padding-top: 55px;
    padding-bottom: 60px;
}
.capabilitiesContainer{
    margin-top: 80px;
}

.videoWrapper{
    display: flex;
    justify-content: center;
    margin-top: 80px;
}

@media (max-width: 768px) {
    .solutionCapabilitiesBlock{
        padding-top: 40px;
        padding-bottom: 0;
    }
    .solutionCapabilitiesBlock > * > h2,.solutionCapabilitiesBlock > * > p{
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
        box-sizing: border-box;
    }
}