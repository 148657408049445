.socialLinkItem{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin: 0 10px;
    transition: .3s all ease;
}

.socialLinkItem:hover{
    background: var(--color-orange-lighter);
}

.socialLinkItem:active{
    transform: scale(.6);
}

.socialLinkItem > svg >{
    width: 10px;
    height: auto;
}

.socialLinkItem > svg > path{
    fill: var(--color-orange-lighter);
    fill-rule: evenodd;
    transition: .3s all ease;
}

.socialLinkItem:hover > svg > path{
    fill: white;
}

@media (max-width: 768px) {
    .socialLinkItem{
        background: var(--color-orange-lighter);
    }
    .socialLinkItem > svg > path{
        fill: white;
    }
}