.container{
    margin-bottom: 80px;
}

.title{
    font-size: 30px;
    text-align: center;
    color: var(--color-blue-text);
}
.iconContainer{
    display: flex;
    justify-content: center;
}
.iconContainer svg{
    fill: var(--color-orange-lighter);
    height: 68px;
}
.cardsContainer{
    display: grid;
    grid-template-columns: repeat(3, 350px);
    grid-row-gap: 30px;
    grid-column-gap: 30px;
}
.outerWrapper{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

@media (max-width: 1200px) {
    .cardsContainer{
        grid-template-columns: repeat(2, 350px);
    }
}

@media (max-width: 768px) {
    .title{
        padding-left: 30px;
        padding-right: 30px;
        font-size: 24px;
        margin-bottom: 10px;
    }
    .cardsContainer{
        width: 100%;
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }
    .cardsContainer::-webkit-scrollbar{
        display: none;
    }
    .outerWrapper{
        margin-top: 20px;
        display: block;
    }
    .mock{
        display: block;
        width: 1px;
        flex-shrink: 0;
        margin-left: -1px;
    }
}