.cardsContainer{
    margin-top: 72px;
    display: grid;
    grid-template-columns: repeat(3, 350px);
    grid-auto-rows: 350px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
}

@media (max-width: 768px) {
    .cardsContainer{
        width: 100%;
        display: block;
        margin-top: 35px;
    }
}