.blockParagraph{
    color: var(--color-blue-text);
    margin: 19px 0;
    font-size: 19px;
    line-height: 30px;
    font-weight: 400;
    /*animation: blockParagraphIntro 1s 1 ease;*/
}

.textCenteredDesktop{
    text-align: center;
}

@keyframes blockParagraphIntro{
    0%{
        transform: translateY(10px);
        opacity: 0;
    }
    100%{
        transform: initial;
        opacity: initial;
    }
}

@media (max-width: 768px) {
    .blockParagraph{
        font-size: 14px;
        line-height: 24px;
    }

    .textCenteredDesktop{
        text-align: initial;
    }

    .textCenteredMobile{
        text-align: center;
    }
}