.cardsContainer{
    display: flex;
    align-items: flex-start;
    margin-top: 56px;
}

@media (max-width: 768px) {
    .cardsContainer{
        margin-top: 20px;
        width: 100%;
        display: block;
    }
}