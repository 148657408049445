.schemeCard{
    position: relative;
    display: inline-block;
    padding: 50px 40px;
    box-sizing: border-box;
    width: calc(33.333% - 18px);
    margin: 16px 0;
    background: white;
    border-radius: 14px;
    border: 1px solid var(--color-light-grey-border);
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
}

.schemeCard:nth-child(even){
    margin-left: 32px;
    margin-right: 32px;
}
.innerContainer{

}

.schemeCard svg{
    height: 270px;
    margin-bottom: 13px;
}

.schemeCard h3{
    color: var(--color-blue-text);
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 14px;
}

.schemeCard p{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-orange-lighter);
}

.indexCircle{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 80px;
    left: 30px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: var(--color-orange-lighter);
}

@media (max-width: 768px) {
    .schemeCard{
        width: 100%;
        display: block;
    }
    .schemeCard:nth-child(even){
        margin-left: initial;
        margin-right: initial;
    }
}