.closeButton{
    display: inline-block;
    padding: 20px;
    border: none;
    background: transparent;
    cursor: pointer;
}

.closeButton svg path{
    fill: #888;
    transition: .3s all ease;
}

.closeButton:hover svg path{
    fill: var(--color-orange-saturated);
}

.closeButton:focus{
    outline: none;
}