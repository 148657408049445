.container{
    padding-top: 55px;
    padding-bottom: 55px;
    background: var(--color-light-grey-bg);
}

.buttonContainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

@media (max-width: 758px) {
    .container{
        padding-top: 35px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .buttonContainer{
        margin-bottom: 0;
    }
}