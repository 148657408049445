.workflowCard{
    padding: 40px;
    box-sizing: border-box;
    width: 100%;
    color: var(--color-blue-text);
    border-radius: 14px;
    background: white;
    border: 1px solid var(--color-light-grey-border);
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
}

.workflowCard .index{
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    border-radius: 100%;
    background: var(--color-orange-lighter);
    margin-bottom: 35px;
}

.workflowCard h3{
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
}

.workflowCard p{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

@media (max-width: 768px) {
    .workflowCard{
        margin: 15px 0;
        padding: 30px;
    }
    .workflowCard p{
        margin-bottom: 0;
    }
}