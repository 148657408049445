.dataProtectionBlock{
    height: 90vh;
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .dataProtectionBlock{
        height: auto;
        display: block;
    }
}