.bannerBlock{
    margin-top: var(--nav-offset-px);
}

.scrollButtonContainer{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .scrollButtonContainer{
        display: none;
    }
}