.menuToggle{
    width: 18px;
    padding: 15px;
}

.menuToggle.expanded{
    width: 19px;
}

.menuToggle.expanded .bar{
    transition: .2s all ease;
}

.menuToggle.expanded .bar:first-child{
    transform: translateY(7px) rotateZ(-45deg);
    transform-origin: center;
}

.menuToggle.expanded .bar:nth-child(2){
    opacity: 0;
}

.menuToggle.expanded .bar:last-child{
    transform: translateY(-7px) rotateZ(45deg);
    transform-origin: center;
}

.menuToggle.collapsed{

}
.bar{
    height: 2px;
    background: var(--color-blue);
    transition: .2s all ease;
}
.bar:nth-child(2){
    margin: 5px 0;
}

@media (min-width: 769px) {
    .menuToggle{
        display: none;
    }
}