.dialogWrapper{
    position: fixed;
    z-index: 2;
    bottom: 20px;
    right: 20px;
    width: 390px;
    min-height: 455px;
    padding: 60px 40px;
    box-sizing: border-box;
    background: white;
    border-radius: 14px;
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
    transition: .3s all ease;
    border: 1px solid var(--color-light-grey-border);
}
.dialogWrapper.shown{

}
.dialogWrapper.hidden{
    opacity: 0;
    pointer-events: none;
    transform: translateX(500px);
}

.closeButtonContainer{
    top: 10px;
    right: 10px;
    position: absolute;
}

@media (max-width: 768px) {
    .dialogWrapper{
        top: var(--nav-offset-px);
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .dialogWrapper.hidden{
        opacity: 0;
        pointer-events: none;
        transform: translateY(500px);
    }
}


@media (max-width: 768px) and (orientation: landscape){
    .dialogWrapper{
        z-index: 3;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 0;
        padding: 30px 20px;
    }
}