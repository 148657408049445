.container{
    padding-top: 60px;
    padding-bottom: 48px;
}

@media(max-width: 768px){
    .container{
        padding: 35px 15px 15px 15px;
    }
    .container > * > p, .container > * > h2{
        padding-left: 15px;
        padding-right: 15px;
    }
}