.navigationBar{
    position: fixed;
    width: 100%;
    top: 0;
    background: white;
    z-index: 3;
    padding: 56px 0;
    box-sizing: border-box;
    transition: .3s all ease;
}

.navigationBar.initial{

}

.navigationBar.scrolled{
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
}

.navigationBar > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.logoLink{
    display: contents;
}

@media (min-width: 769px) {
    .logoLink:hover  svg path{
        fill: var(--color-orange-saturated)!important;
    }
}

@media (max-width: 1440px) {
    .navigationBar{
        padding: 30px 0;
    }
    .navigationBar.scrolled{
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media (max-width: 1240px) {
    .navigationBar{
        padding: 30px;
    }
    .navigationBar.scrolled{
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (max-width: 940px) {
    .socialLinksWrapper{
        display: none;
    }
}

@media (max-width: 768px) {
    .navigationBar, .navigationBar.mobileMenuExpanded{
        width: 100%;
        padding: 25px 20px;
        padding-left: 35px;
    }

    .navigationBar.initial{

    }

    .navigationBar.scrolled.mobileMenuCollapsed{
        padding: 15px 20px;
        padding-left: 35px;
    }

}