.bannerContainer{
    width: 100%;
    height: 600px;
    background: var(--color-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 70px;
    box-sizing: border-box;
    background-size: cover!important;
    background-position: center!important;
}

.title{
    margin-top: 50px;
    margin-bottom: 30px;
    color: white;
    text-align: center;
    font-size: 60px;
    line-height: 72px;
    font-weight: bold;
    width: 600px;
}

.bannerContainer svg{
    width: 60px;
    height: 60px;
}

.bannerContainer svg path{
    fill: var(--color-orange-mid);
}

@media (max-width: 768px) {
    .bannerContainer{
        padding-left: 30px;
        padding-right: 30px;
        align-items: flex-start;
    }
    .title{
        width: 100%;
        font-size: 30px;
        line-height: 36px;
        text-align: left;
        margin-top: 30px;
    }
    .bannerContainer button{
        margin: 12px 0;
    }
}