.wrapper{
    background: black;
}
.bannerImageWrapper{
    width: 100vw;
    height: 100vh;
    padding: 20px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background-size: cover!important;
    background-position: top right!important;
    display: flex;
    align-items: center;
    background: black;
}
.bgGradient{
    background: linear-gradient(90deg, rgba(0,0,0,0.6) 40%, rgba(255,255,255,0) 100%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.bannerContent{
    position: relative;
    z-index: 2;
    margin: 30px 0;
    margin-left: 40px;
    max-width: 100%;
    box-sizing: border-box;
}
.mainHeader{
    color: white;
    margin-top: 0;
    font-size: 60px;
    line-height: 72px;
    font-weight: bold;
    width: 800px;
    max-width: 100%;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
}

.buttonContainer{
    margin-top: 40px;
}

.features{
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
    color: white;
    margin: 30px 0;
}

.feature{
    margin-bottom: 10px;
}

.featureTitle{
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    color: white;
}

.aboutContainer{
    z-index: 2;
    margin-top: 40px;
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoContainer{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.logoContainer svg path{
    fill: white;
}

.logoContainer svg{
    width: 33px;
    height: 12px;
}

.copyrightText{
    color: white;
    font-size: 12px;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}

.cardsWrapper{
    position: relative;
}

.scrollCardsButtons{

}

.scrollCardsButton{
    z-index: 1;
    position: absolute;
    top: calc(50% - 30px);
    width: 60px;
    height: 60px;
    backdrop-filter: blur(8px);
    background: rgb(255 255 255 / 5%);
    border-radius: 100%;
    border: none;
    box-shadow: 0 0px 16px 3px #0000007a;
    transition: .3s transform ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scrollCardsButton:active{
    transform: scale(.9);
}
.scrollCardsButton.left{
    left: -20px;
}
.scrollCardsButton.right{
    right: -30px;
}

.scrollCardsButton .chevron{
    font-size: 30px;
    color: white;
    line-height: 0;
    margin-top: -4px;
}

.cardsContainer{
    position: relative;
    z-index: 0;
    display: flex;
    width: 1100px;
    max-width: 90vw;
    overflow-x: auto;
}


.cardsContainer::-webkit-scrollbar{
    display: none;
}

.hidden{
    display: none;
}

.card{
    overflow: hidden;
    position: relative;
    padding: 40px;
    margin: 0 10px;
    box-sizing: border-box;
    width: 100%;
    min-width: 480px;
    height: 280px;
    border-radius: 14px;
    color: white;
    backdrop-filter: blur(6px);
    background-color: rgba(0, 0, 0, 0.30);
    scroll-snap-align: center;
}

.cardIconWrapper img{
    height: 64px;
    width: auto;
}

.cardIndex{
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 12px;
    font-weight: 300;
    color: var(--color-light-grey-border)
}

.bgVideo{
    position: fixed;
    z-index: 0;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    object-fit: cover;
}

.bgVideo.hidden{
    opacity: 0;
}

.mock{
    display: none;
}

@media (min-width: 769px) {
    .cardIndex{
        display: none;
    }
}

@media (max-width: 1024px) {
    .bannerContent{
        margin: 0 20px;
        max-width: calc(100% - 40px);
    }
    .scrollCardsButton.left{
        left: -10px;
    }
    .scrollCardsButton.right{
        right: -10px;
    }
}

@media (max-width: 768px) {
    .mock{
        display: block;
        width: 1px;
        flex-shrink: 0;
        margin-left: -1px;
    }
    .bgGradient {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 40%, rgba(255, 255, 255, 0) 100%);
    }
    .bannerImageWrapper{
        display: block;
    }
    .cardsContainer{
        width: 100%;
        max-width: 100%;
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        margin-bottom: 10px;
    }
    .card{
        min-width: calc(100vw - 30px);
        margin: 0 15px;
        padding: 20px 15px;
        min-height: auto;
        height: auto;
    }
    .scrollCardsButton{
        display: none;
    }
    .mainHeader{
        box-sizing: border-box;
        width: 100%;
        font-size: 28px;
        line-height: initial;
        text-shadow: 0 0 3px rgba(0, 0, 0, 1);
        padding: 0 15px;
    }
    .bannerContent{
        z-index: 2;
        margin: 0;
        max-width: 100%;
        padding: 15px 0;
        border-radius: 15px;
    }
    .buttonContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
        padding: 0 15px;
    }

    .buttonContainer a{
        display: flex;
    }

    .buttonContainer button{
        flex-basis: 100%;
        margin-left: 0;
        margin-right: 0;
        backdrop-filter: blur(3px);
    }

    .aboutContainer{
        z-index: 1;
    }

    .logoContainer{
        display: flex;
        justify-content: center;
    }

    .copyrightText{
        text-align: center;
        font-size: 10px;
    }

    .features{
        text-shadow: 0 0 3px rgba(0, 0, 0, 1);
    }
    .feature{
        font-size: 14px;
    }
    .featureTitle{
        font-size: 18px;
    }
}

@media (max-width: 768px) and (orientation: landscape) {
    .buttonContainer{
        display: flex;
        flex-direction: row;
    }

    .buttonContainer > *{
        margin-right: 0;
    }

    .buttonContainer > *:first-child{
        margin-right: 20px;
    }
}

@media (max-width: 320px) {
    .bannerContent{
        padding: 0;
    }
    .mainHeader{
        font-size: 24px;
    }
    .featureTitle{
        font-size: 16px;
    }
    .feature{
        font-size: 12px;
    }
}